

























import { Component, Vue } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import VClamp from 'vue-clamp'
import { productStore } from '~/store'
const Pagination = () => import('~/components/layouts/Pagination.vue')

@Component({
  components: { VClamp, Pagination },
})
export default class DateList extends Vue {
  count: number = 9
  currentPage: number = 1

  async applyPage(value: number) {
    await productStore.setPage(value)
    await productStore.initPosts({
      slug: '',
      date:
        typeof this.$nuxt.$route.params.date === 'undefined'
          ? ''
          : this.$nuxt.$route.params.date,
      category:
        typeof this.$nuxt.$route.params.category === 'undefined'
          ? ''
          : this.$nuxt.$route.params.category,
    })
  }

  get posts() {
    return productStore.posts
  }

  get page() {
    return productStore.page
  }

  get pagesTotal() {
    return productStore.pagesTotal
  }

  getDate(date: Date) {
    return dayjs(date).format('YYYY.MM.DD')
  }

  interval1: any = null
  interval2: any = null

  beforeDestroy() {
    clearInterval(this.interval1)
  }
}
